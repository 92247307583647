<template>
  <div class="page-container">
    <div class="page-top">
      <el-form label-position="right" label-width="85px" :inline="true">
          <el-form-item label="时间筛选：">
              <el-date-picker v-model="form.Time"
                              type="daterange"
                              range-separator="至" size="small"
                              start-placeholder="开始日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="handleSearch()"
                              end-placeholder="结束日期" style="width:250px;">
              </el-date-picker>
          </el-form-item>
          <el-button-group>
              <el-button type="default" @click="handleRecent(3)">最近3天</el-button>
              <el-button type="default" @click="handleRecent(7)">最近7天</el-button>
              <el-button type="default" @click="handleRecent(15)">最近15天</el-button>
              <el-button type="default" @click="handleRecent(30)">最近30天</el-button>
          </el-button-group>
      </el-form>
    </div>
    <div style="width:100%;height:250px;">
        <div ref="chart" style="width:100%; height: 100%; margin-top: 10px"></div>
    </div>
    <div class="page-table" style="margin:0px 10px 0px 10px;">
        <el-table ref="reportTable" :data="tableConfig.data" v-loading="tableConfig.loading" show-summary border height="100%"
                  :header-cell-style="{'background-color': '#E5EEF6','border-color': 'rgba(218, 223, 228, 1)',height: '48px',}">
            <el-table-column prop="ReportDate"
                             label="日期"
                             header-align="center" align="center">
            </el-table-column>
            <el-table-column prop="TradeCount"
                             label="报单数"
                             header-align="center" align="center">
                <template slot-scope="{ row }">
                    {{row.TradeCount}}
                </template>
            </el-table-column>
            <el-table-column prop="ProductCount"
                             label="配货数"
                             header-align="center" align="center">
                <template slot-scope="{ row }">
                    {{row.ProductCount}}
                </template>
            </el-table-column>
            <el-table-column prop="TotalPayment"
                             label="收银数"
                             header-align="center" align="center">
                <template slot-scope="{ row }">
                    {{row.TotalPayment}}
                </template>
            </el-table-column>
            <el-table-column prop="RefundCount"
                             label="退货单数"
                             header-align="center" align="center">
                <template slot-scope="{ row }">
                    {{row.RefundCount}}
                </template>
            </el-table-column>
            <el-table-column prop="TotalRefundProductCount"
                             label="退货商品数"
                             header-align="center" align="center">
                <template slot-scope="{ row }">
                    {{row.TotalRefundProductCount}}
                </template>
            </el-table-column>
            <el-table-column prop="TotalRefundFee"
                             label="退货金额"
                             header-align="center" align="center">
                <template slot-scope="{ row }">
                    {{row.TotalRefundFee}}
                </template>
            </el-table-column>

        </el-table>
    </div>
  </div>
</template>

<script>
    import { submit, exportFile } from "@/api/base";
    import { getDate } from "@/utils/tool";

    export default {
        name: "stallNewDailyReport",
        data() {
            return {
                width: 0,
                height: 0,
                form: {
                    Time: []
                },
                pickerOptions: {
                    onPick: (time) => {
                        if (time != null && time.minDate != null && time.maxDate != null) {
                            this.handleSearch();
                        }
                    },
                },
                tableConfig: {
                    loading: false,
                    url: "/api/stall/getDailyReport",
                    data: []
                },
            };
        },
        mounted() {
            var that = this;
            window.addEventListener('resize', that.getDimensions);
            var today = that.getToday();
            that.form.Time = [today, today];
            that.$nextTick(() => {
                that.getDimensions();
                that.handleSearch();
            });
        },
        methods: {
            getToday(date, addDay) {
                if (date == undefined) {
                    date = new Date();//当前时间
                } else {
                    var dateArr = date.split("-");
                    var year = parseInt(dateArr[0]);
                    var month;
                    if (dateArr[1].indexOf("0") == 0) {
                        month = parseInt(dateArr[1].substring(1));
                    } else {
                        month = parseInt(dateArr[1]);
                    }
                    var day = parseInt(dateArr[2]);
                    date = new Date(year, month - 1, day);
                }
                if (addDay != undefined) {
                    date = date.setDate(date.getDate() + addDay);
                    date = new Date(date);
                }
                //获取年份  
                var Y = date.getFullYear();
                //获取月份  
                var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
                //获取当日日期 
                var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                return Y + '-' + M + '-' + D;
            },
            //窗体大小改不事件
            getDimensions() {
                this.width = document.documentElement.clientWidth;
                this.height = document.documentElement.clientHeight;
                document.querySelector(".page-table .el-table").style.height = (this.height - 515) + "px";
                var tbOrderDom = document.querySelector(".page-table .el-table__body-wrapper");
                tbOrderDom.style.overflowY = "auto";
                tbOrderDom.style.height = (this.height - 604) + "px";
                tbOrderDom.scrollTop = "0px";

                const chart = this.$refs.chart;
                let myChart = this.$echarts.init(chart);
                myChart.resize();
            },
            async handleSearch() {
                var that = this;
                var param = {
                    CurrentPage: 1,
                    PageSize: 99999999
                };
                if (that.form.Time != null && that.form.Time.length > 0) {
                    param.StartTime = that.getToday(that.form.Time[0]);
                    param.EndTime = that.getToday(that.form.Time[1]);
                }
                that.tableConfig.loading = true;
                const { data } = await submit(
                    that.tableConfig.url,
                    param
                ).catch((error) => {
                    that.tableConfig.loading = false;
                });
                that.tableConfig.data = data.datas;
                that.setEchartData();
                that.tableConfig.loading = false;
            },
            handleRecent(days) {
                var that = this;
                var end = that.getToday();
                var start = that.getToday(end, -(days - 1));
                that.form.Time = [start, end];
                that.handleSearch();
            },
            setEchartData() {
                const chart = this.$refs.chart;
                if (chart) {
                    let myChart = this.$echarts.init(chart);
                    const option = {
                        legend: { data: [] },
                        xAxis: {},
                        yAxis: {},
                        series: [],
                        tooltip: {
                            trigger: 'axis',
                            show: true,
                            // 自定义修改悬浮内容
                            formatter: function (params) {
                                var result = params[0].name;
                                var hasValue = false;
                                params.forEach(function (item) {
                                    if (item.value > 0) {
                                        hasValue = true;
                                        result += '<br/>';
                                        result += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + item.color + '"></span>';
                                        result += item.seriesName + '：' + '<b>' + item.value + '</b>';
                                    }
                                });
                                if (hasValue) {
                                    return result;
                                }
                                return "";
                            }
                        },
                    };
                    option.series = [
                        { type: "line", name: "报单数", data: [] },
                        { type: "line", name: "配货数", data: [] },
                        { type: "line", name: "收银数", data: [] },
                        { type: "line", name: "退货单数", data: [] },
                        { type: "line", name: "退货商品数", data: [] },
                        { type: "line", name: "退货金额", data: [] },
                    ];
                    const legend = [];
                    if (this.tableConfig.data.length > 0) {
                        this.tableConfig.data.forEach((item) => {
                            legend.push(item.ReportDate);
                            option.series[0].data.push(item.TradeCount);
                            option.series[1].data.push(item.ProductCount);
                            option.series[2].data.push(item.TotalPayment);
                            option.series[3].data.push(item.RefundCount);
                            option.series[4].data.push(item.TotalRefundProductCount);
                            option.series[5].data.push(item.TotalRefundFee);
                        });
                    }
                    option.series.map((item) => {
                        option.legend.data.push(item.name);
                    });
                    option.xAxis.data = legend;
                    myChart.setOption(option);
                    this.$on("hook:destroyed", () => {
                        window.removeEventListener("resize", function () {
                            myChart.resize();
                        });
                    });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
.page-top {
  padding: 6px 14px 0 14px;
}
</style>
